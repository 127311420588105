import * as React from 'react';

import { connect } from 'react-redux';
import Footer from '../components/Footer';
import Layout from '../components/layout';
import Perlite from '../components/perlite/Perlite';
import Seo from '../components/seo';

const PerlitePage = () => {
  return (
    <Layout>
      <Seo title="Perlite" />
      <Perlite />
      <Footer />
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user
});

export default connect(mapStateToProps)(PerlitePage);
