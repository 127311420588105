import React from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
import perlite1 from '../../images/perlite/perlite1.jpg';
import perlite2 from '../../images/perlite/perlite2.jpg';
import perlite3 from '../../images/perlite/perlite3.jpg';
import perlite4 from '../../images/perlite/perlite4.jpg';
import perlite5 from '../../images/perlite/perlite5.jpeg';
import perlite6 from '../../images/perlite/perlite6.jpeg';
import perlite7 from '../../images/perlite/perlite7.jpeg';
import perlite8 from '../../images/perlite/perlite8.jpeg';

const PertileGallery = () => {
  const images = [
    { url: perlite1 },
    { url: perlite2 },
    { url: perlite3 },
    { url: perlite4 },
    { url: perlite5 },
    { url: perlite6 },
    { url: perlite7 },
    { url: perlite8 }
  ];
  return (
    <div className="d-flex center w-100">
      <SimpleImageSlider
        width={406}
        height={604}
        showBullets={true}
        showNavs={true}
        images={images}
      />
    </div>
  );
};

export default PertileGallery;
