import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

const PerliteUses = () => {
  return (
    <>
      <p className="mt-5 mb-3 text-700 text-xl text-center">
        Applications <span className="green-c">&</span> Uses
      </p>

      <div className="px-2 md:px-3 lg:px-5 pt-0">
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">Lightweight Aggregate</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite makes a lightweight concrete for roof decks and an insulative base for in-ground vinyl pools Perlite is used as an extremely lightweight aggregate for insulating concrete in roof decking, floors, decorative stone products, bricks and tiles, tilt-up panels, pottery, cast sculpture, and more. By following speciﬁc procedures, typical ready-mix batching equipment and trucks can be used for perlite concrete.
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">As an Insulator</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite is used a loose fill insulator in double-walled storage tanks and block walls. As a lightweight insulator, perlite is incredibly versatile, meeting a variety of insulating needs—from the super-cooling demands of cryogenics to ambient and low temperature applications up through intense high temperature demands such as foundry cores, molds and even as a crucible topping. Perlite is non—combustible, with fusion point of 1260 degrees C (2300F) and is an ideal loose-ﬁll or spray-on ﬁre-proof insulation for safes, safe rooms, doors, pizza ovens, and chimney linings
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">Horticultural Applications</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite is widely used for horticultural and agricultural endeavors Perlite is widely used in horticultural applications because of its lightweight water~holding capacity, neutral pH, and pathogen-free nature. Perlite is found in the engineered lightweight soils of roof gardens and planters as well as commercial and bagged potting soils. Perlite is used a soilless grow media forhydroponics, seed starting and plant rooting. Perlite also makes an excellent conditioner for native garden, turf, and landscape soils, enhancing both the water-holding capacity and anti-compaction properties
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">For Plasters and Textured Coatings</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite makes for a lightweight texture in ceiling ﬁnishes and as a functional replacement for sand in plaster and stucco Perlite’s acoustical, fire-proofing, and insulative properties as well as its lightweight form factor make it an ideal sand-replacing aggregate for interior and exterior plasters and stuccos. Perlite also works to bulk and pattern textured coatings and finishes.
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">For Waste and Spill Management</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite is used as a mineral sponge for oil and chemical spills Perlite is used in environmental applications to absorb oil and to soak up and stabilize chemical spills and other liquid pollutants. Perlite is also blended with soil to ﬁlter storm water runoff from paved surfaces, mitigate rainwater surge and erosion, and solidify liquid waste and industrial sludge for legal transport.
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">As a Filtration Media</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite is used as a pool ﬁlter media and to filter storm runoff Beer, wine, juices, chemicals, pharmaceuticals, oils, acids, sugars, bio-diesels, and water (potable, swimming pool, and storm runoff) are all filtered with perlite that has been expanded and crushed to form a complex maze of microscopic pathways that makes a highly-effective filter cake.
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">As a Lightweight Filler</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite works as a lightweight functional filler for cultured marble products and ceiling tiles. Perlite serves as a lightweight filler for caulking compounds, paints, plastics, and explosives. When added to soaps, cleansers, polishes, dental compounds and stone wash wheels, perlite adds gentle yet effective abrasive properties to the product. Perlite is also added to plasters, ceiling tiles and walls to enhance sound absorption in these materials
            </div>
          </div>
        </div>
        <div className="mb-5 flex">
          <div className="flex flex-column align-items-center" style={{ width: '2rem' }}>
            <span className="bg-green-500 text-0 flex align-items-center justify-content-center border-circle" style={{ minWidth: '2.5rem', minHeight: '2.5rem' }}>
              <FiCheckCircle />
            </span>
            <div className="h-full bg-green-500" style={{ width: '2px' }}></div>
          </div>
          <div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div className="mb-3">
              <span className="text-900 font-medium inline-block mr-3">For Sandblasting and as a Slag Coagulant</span>
            </div>
            <div className="line-height-3 text-700 mb-3">
              Expanded perlite makes for a lightweight texture in ceiling ﬁnishes and as a functional replacement for sand in plaster and stucco. In its crude ore state, perlite has industrial uses—chiefly in the foundry industry and as a sandblasting media.
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default PerliteUses;
