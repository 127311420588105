import React from 'react';
import introImg from '../../images/intro-img.jpeg';
import perlite from '../../images/perlite/perlite_cropped.jpg';
import PerliteUses from './PerliteUses';
import PerliteGallery from './PertileGallery';

const Perlite = () => {
  return (
    <div
      className="vermiculite-intro-wrapper pb-3"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        style={{ height: '200px', background: `url(${introImg}) no-repeat` }}
        className="vermiculite-intro"
      ></div>
      <div className="px-4 py-5 md:px-6 lg:px-8">
        <div
          className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
          style={{ marginTop: '-2rem', top: '-70px', marginBottom: '-70px' }}
        >
          <div>
            <div
              style={{ width: '140px', height: '140px', borderRadius: '10px' }}
              className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
            >
              <img
                src={perlite}
                alt="vermiculite"
                className="border-round shadow-3"
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="mb-3">
              <div className="border-bottom-1 surface-border">
                <span className="block text-3xl font-medium text-900 mb-4">
                  Perlite
                </span>
              </div>
            </div>
            <p className="mt-0 mb-3 text-700 text-xl">
              What is <span className="green-c">Perlite</span>?
            </p>
            <div className="text-600 font-medium">
              <p className="mb-3">
                Perlite is one of nature’s most versatile and efficient
                minerals. Its unique characteristics of being lightweight,
                sterile, insulating, and fireproof make it an important element
                in construction, agriculture and industrial applications.
              </p>
            </div>
            <p className="mt-5 mb-3 text-700 text-xl text-center">
              Perlite <span className="green-c">Gallery</span>
            </p>
            <PerliteGallery />
            <PerliteUses />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Perlite;
